<template>
  <div id="map">
    <!-- <div 
        v-for="(value, key) in stationDetails"
        :key="key"
        >
            <div v-bind:id="'label_' + value.STATION_ID">
                {{ value.STATION_ID}}
            </div>
            <div v-if="value.param_wqi_class === 'I'">
                <div class="reading-div reading-blue" v-bind:id="'reading_' + value.STATION_ID">
                    {{value.param_wqi}}
                </div>
            </div>
            <div v-else-if="value.param_wqi_class === 'II'">
                <div class="reading-div reading-green" v-bind:id="'reading_' + value.STATION_ID">
                    {{value.param_wqi}}
                </div>
            </div>
            <div v-else-if="value.param_wqi_class === 'III'">
                <div class="reading-div reading-yellow" v-bind:id="'reading_' + value.STATION_ID">
                    {{value.param_wqi}}
                </div>
            </div>
            <div v-else-if="value.param_wqi_class === 'IV'">
                <div class="reading-div reading-orange" v-bind:id="'reading_' + value.STATION_ID">
                    {{value.param_wqi}}
                </div>
            </div>
            <div v-else-if="value.param_wqi_class === 'V'">
                <div class="reading-div reading-red" v-bind:id="'reading_' + value.STATION_ID">
                    {{value.param_wqi}}
                </div>
            </div>
            <div v-else>
                <div class="reading-div reading-grey" v-bind:id="'reading_' + value.STATION_ID">
                    N/A
                </div>
            </div>
        </div> -->
    <div class="dashboard-btn">
      <v-btn
        class="ma-0 primary"
        dark
        @click="goToPage('/Dashboard')"
      >
        <v-icon
          dark
          left
        >
          mdi-view-dashboard-outline
        </v-icon>Dashboard
      </v-btn>
    </div>
    <div id="map-btn">
      <div class="map-icon-btn">
        <v-select
          :items="items"
          label="Stations"
          dense
          solo
          hide-details
          v-on:change="zoomStation"
          append-icon="mdi-magnify"
        >
        </v-select>
      </div>
      <div class="map-icon-btn">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="home()"
            >
              <v-icon class="primary--text" role="img">mdi-home</v-icon>
            </v-btn>
          </template>
          <span>Home</span>
        </v-tooltip>
      </div>
      <div class="map-icon-btn">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="
                displayCard = false;
                displayCardMap = !displayCardMap;
              "
            >
              <v-icon class="primary--text" role="img">mdi-map</v-icon>
            </v-btn>
          </template>
          <span>Basemap / Map View</span>
        </v-tooltip>
      </div>
      <div class="map-icon-btn">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="
                displayCardMap = false;
                displayCard = !displayCard;
              "
            >
              <v-icon class="primary--text" role="img"
                >mdi-information-variant</v-icon
              >
            </v-btn>
          </template>
          <span>Information</span>
        </v-tooltip>
      </div>
      <div class="map-icon-btn">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="pinOfInterestSetting"
            >
              <v-icon class="primary--text" role="img">mdi-map-marker</v-icon>
            </v-btn>
          </template>
          <span>Point Of Interest</span>
        </v-tooltip>
      </div>
    </div>
    <v-card
      class="mx-auto info-card"
      :max-width="$vuetify.breakpoint.xs ? '250' : '280'"
      outlined
      v-show="displayCard"
    >
      <v-card-title class="pa-2 primary">
        <span class="text-h6 white--text">INFORMATION</span>

        <v-spacer></v-spacer>

        <v-btn dark icon small @click="displayCard = !displayCard">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-list-item>
        <v-list-item-content class="pa-0">
          <div class="text-overline">Station Status</div>
          <v-list-item
            v-for="item in station_status"
            :key="item.station_status"
            class="height-list"
          >
            <v-list-item-avatar>
              <v-img max-height="32" max-width="23" :src="item.img"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="text-overline">WQI Class</div>
          <v-list-item
            v-for="(item, index) in wqi_class"
            :key="index"
            class="height-list"
          >
            <v-list-item-avatar>
              <v-img max-height="32" max-width="23" :src="item.img"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-card
      class="mx-auto info-card"
      :max-width="$vuetify.breakpoint.xs ? '250' : '280'"
      outlined
      v-show="displayCardMap"
    >
      <v-card-title class="pa-2 primary">
        <span class="text-h6 white--text">MAP VIEW</span>

        <v-spacer></v-spacer>

        <v-btn dark icon small @click="displayCardMap = !displayCardMap">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-list-item>
        <v-list-item-content class="pa-0">
          <div class="text-overline">Map Selection</div>
          <v-list-item
            v-for="item in basemap_list"
            :key="item.basemap_list"
            class="height-list"
            v-on:click="changeBasemap(item.text)"
          >
            <v-list-item-avatar tile>
              <v-img max-height="50" max-width="50" :src="item.img"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-card
      class="mx-auto station-info-card"
      :max-width="$vuetify.breakpoint.xs ? '320' : '425'"
      max-height="85%"
      outlined
      v-show="displayInformation"
      v-if="selectedFeature !== '' && selectedFeature.stationid_a !== undefined"
    >
      <v-card-title class="pa-1 primary">
        <v-spacer></v-spacer>

        <v-btn
          dark
          icon
          x-small
          @click="displayInformation = !displayInformation"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="primary" v-if="selectedFeature !== ''">
        <v-list-item three-line dark class="pl-0 pr-0">
          <v-list-item-avatar class="mr-2">
            <v-img
              max-height="32"
              max-width="22"
              alt=""
              src="../assets/mapviewer/station pin.svg"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="text-overline" id="info_station_id">
              STATION {{ selectedFeature.STATION_ID }}
            </div>
            <div id="info_location">{{ selectedFeature.LOCATION }}</div>
            <div>Last Updated:</div>
            <div>{{ new Date(selectedFeature.DATETIME).toLocaleDateString('en-GB') + ', ' + new Date(selectedFeature.DATETIME).toLocaleTimeString() }}</div>
          </v-list-item-content>

          <v-list-item-content v-if="selectedFeature.param_wqi_class === 'I'">
            <div class="reading-info-card reading-blue">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content
            v-else-if="selectedFeature.param_wqi_class === 'II'"
          >
            <div class="reading-info-card reading-green">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content
            v-else-if="selectedFeature.param_wqi_class === 'III'"
          >
            <div class="reading-info-card reading-yellow">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content
            v-else-if="selectedFeature.param_wqi_class === 'IV'"
          >
            <div class="reading-info-card reading-orange">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content
            v-else-if="selectedFeature.param_wqi_class === 'V'"
          >
            <div class="reading-info-card reading-red">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
          <v-list-item-content v-else>
            <div class="reading-info-card reading-grey">
              <div class="text-h4" id="info_reading">
                {{ selectedFeature.param_wqi == '-1' ? 'NA' :selectedFeature.param_wqi }}
              </div>
              <div class="text-h6" id="info_class">
                CLASS {{ selectedFeature.param_wqi_class == '-1' || selectedFeature.param_wqi_class == null ? 'NA' :selectedFeature.param_wqi_class }}
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <template>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>WQI PARAMETER</v-expansion-panel-header>
            <v-expansion-panel-content>
              <template>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="pa-0"></th>
                        <th class="text-left">Parameter</th>
                        <th class="text-left">Value & Unit</th>
                        <th class="text-left">Sub Index</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, key) in parameter" :key="key">
                        <td class="pa-0">
                          <span
                            v-if="value.class == 'I'"
                            class="param-class-circle reading-blue"
                          ></span>
                          <span
                            v-if="value.class == 'II'"
                            class="param-class-circle reading-green"
                          ></span>
                          <span
                            v-if="value.class == 'III'"
                            class="param-class-circle reading-yellow"
                          ></span>
                          <span
                            v-if="value.class == 'IV'"
                            class="
                              param-class-circle
                              reading-orange
                              alert-class
                            "
                          ></span>
                          <span
                            v-if="value.class == 'V'"
                            class="param-class-circle reading-red alert-class"
                          ></span>
                          <span
                            v-if="value.class == 'NA'"
                            class="param-class-circle reading-grey"
                          ></span>
                          <span v-if="value.class == undefined"></span>
                        </td>
                        <td class="pr-0">{{ value.fullname }}</td>
                        <td v-if="value.fullname == 'Water Quality Index'">
                          {{
                            value.value == null || value.value.trim() == '-1' ? 'NA' : value.value 
                          }}
                        </td>
                        <td v-else>
                          <!-- {{
                            value.value == null
                              ? ""
                              : value.unit == null
                              ? value.value
                              : value.value + " " + value.unit
                          }} -->
                          <span v-if="value.value.trim() == 'DOR' || value.value.trim() == 'OUTL' || value.value.trim() == 'C' || value.value.trim() == 'M'" class="red--text">
                            {{ value.value }}                                      
                          </span>
                          <span v-else>
                            {{ value.value }}
                          </span>
                        </td>
                        <td>
                          {{ value.sub_index != null ? value.sub_index : "" }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="station_id !== ''">
            <v-expansion-panel-header>STATION DETAILS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list class="transparent pt-0">
                <v-list-item dense>
                  <v-list-item-title>Station ID: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.STATION_ID }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Station Location: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LOCATION }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Longitude: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LONGITUDE }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Latitude: </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ selectedFeature.LATITUDE }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-title>Station Picture: </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-row>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.STATION_PICTURE !== ''"
                    >
                      <v-img
                        :src="selectedFeature.STATION_PICTURE"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.DEPLOYMENT_PICTURE !== ''"
                    >
                      <v-img
                        :src="selectedFeature.DEPLOYMENT_PICTURE"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.UPSTREAM_PICTURE !== ''"
                    >
                      <v-img
                        :src="selectedFeature.UPSTREAM_PICTURE"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex child-flex"
                      cols="6"
                      v-if="selectedFeature.DOWNSTREAM_PICTURE !== ''"
                    >
                      <v-img
                        :src="selectedFeature.DOWNSTREAM_PICTURE"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-card>

    <v-card
      class="mx-auto station-info-card"
      :max-width="$vuetify.breakpoint.xs ? '350' : '425'"
      min-width="350"
      max-height="90%"
      outlined
      v-show="displayInformation"
      v-else
    >
      <v-card-title class="pa-2 primary">
        <h5>{{ selectedFeature.name }}</h5>

        <v-spacer></v-spacer>

        <v-btn
          dark
          icon
          x-small
          @click="
            displayInformation = !displayInformation;
            removeHighlight();
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-title>Coordinate:</v-list-item-title>

            <v-list-item-subtitle class="poi-description overflow-visible">
              {{ selectedFeature.longitude + ", " + selectedFeature.latitude }}
            </v-list-item-subtitle>
          </v-list-item>
          <!-- <v-list-item>
                        <v-list-item-title>Latitude:</v-list-item-title>

                        <v-list-item-subtitle>
                            {{ selectedFeature.latitude }}
                        </v-list-item-subtitle>
                    </v-list-item> -->
          <v-list-item>
            <v-list-item-title>Description:</v-list-item-title>

            <v-list-item-subtitle class="poi-description overflow-visible">
              {{ selectedFeature.description }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Picture:</v-list-item-title>

            <v-list-item-subtitle class="poi-description overflow-visible">
              <template>
                <v-row>
                  <v-col
                    class="d-flex child-flex"
                    cols="12"
                    v-if="selectedFeature.picturePath !== ''"
                  >
                    <v-img
                      :src="selectedFeature.picturePath"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </template>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "ol/ol.css";

// import * as ol from 'ol';
import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
} from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";

// Axios for fetching data
import axios from "axios";

// var raster = new TileLayer({
//     source: new OSM(),
// });

// var layerOpenStreet = new TileLayer({
//     title: 'OSM',
//     type: 'base',
//     source: new OSM({
//         "url" : 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
//     }),
//     visible: true
// });

export default {
  data: () => ({
    displayCard: false,
    displayCardMap: false,
    displayInformation: false,
    station_id: "",
    overlayInsert: false,
    map: null,
    view: null,
    extent: [112.022942, 1.711069, 114.712705, 3.058041],
    // extent: [109.018853, 0.671687, 116.043022, 5.368743],
    items: [],
    selectedItem: 1,
    station_status: [
      {
        text: "Active",
        img: require("@/assets/mapviewer/station_icon_green.svg"),
      },
      {
        text: "Inactive",
        img: require("@/assets/mapviewer/station_icon_grey.svg"),
      },
      {
        text: "Transmission Delay",
        img: require("@/assets/mapviewer/station_icon_orange.svg"),
      },
    ],
    wqi_class: [
      { text: "Class I", img: require("@/assets/mapviewer/wqi_icon_blue.svg") },
      {
        text: "Class II",
        img: require("@/assets/mapviewer/wqi_icon_green.svg"),
      },
      {
        text: "Class III",
        img: require("@/assets/mapviewer/wqi_icon_yellow.svg"),
      },
      {
        text: "Class IV",
        img: require("@/assets/mapviewer/wqi_icon_orange.svg"),
      },
      { text: "Class V", img: require("@/assets/mapviewer/wqi_icon_red.svg") },
      {
        text: "NA",
        img: require("@/assets/mapviewer/wqi_icon_grey.svg"),
      },
    ],
    basemap_list: [
      {
        text: "Google Streets Map",
        img: require("@/assets/mapviewer/googlestreetmap.jpg"),
      },
      {
        text: "Google Satelite Map",
        img: require("@/assets/mapviewer/googlesatelitemap.jpg"),
      },
      {
        text: "Topographic Map",
        img: require("@/assets/mapviewer/topo_map.jpg"),
      },
      {
        text: "Imagery Map",
        img: require("@/assets/mapviewer/imagery_map.jpeg"),
      },
      {
        text: "Streets Map",
        img: require("@/assets/mapviewer/streetsmap.jpg"),
      },
    ],
    baseMapLayer: {
      topographicMap: new TileLayer({
        title: "Topographic Map",
        source: new OSM({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      imageryMap: new TileLayer({
        title: "Imagery Map",
        source: new OSM({
          // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      streetMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
      googleSateliteMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
    },
    stationDetails: "",
    parameterDetails: "",
    selectedFeature: "",
    wqi_parameter: [
      {
        parameter: [],
        subIndex: [],
      },
    ],
    pointOfInterestDetails: "",
    pointOfInterestVisible: false,
    testParameterDetails: [],
    parameter: [],
    selectedPOI: "",
  }),

  methods: {
    todo: function () {
      this.intervalid1 = setInterval(() => {
        axios
          .get("https://apis.spatialworks.com.my/sarawak/cms/reading", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.stationDetails = response.data;
            console.log(this.stationDetails);

            for (let i = 0; i < this.stationDetails.length; i++) {
              let elementId = "reading_" + this.stationDetails[i].stationid_a;

              let div = document.getElementById(elementId);
              div.className = "";

              if (this.stationDetails[i].param_wqi_class == "I") {
                div.setAttribute("class", "reading-div reading-blue");
              } else if (this.stationDetails[i].param_wqi_class == "II") {
                div.setAttribute("class", "reading-div reading-green");
              } else if (this.stationDetails[i].param_wqi_class == "III") {
                div.setAttribute("class", "reading-div reading-yellow");
              } else if (this.stationDetails[i].param_wqi_class == "IV") {
                div.setAttribute(
                  "class",
                  "reading-div reading-orange alert-class"
                );
              } else if (this.stationDetails[i].param_wqi_class == "V") {
                div.setAttribute(
                  "class",
                  "reading-div reading-red alert-class"
                );
              } else {
                div.setAttribute("class", "reading-div reading-grey");
              }

              div.innerHTML =
                // this.stationDetails[i].param_wqi_class == "NA"
                //   ? "N/A" :
                  this.stationDetails[i].param_wqi;
            }
            // console.log(this.stationDetails);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5 * 60 * 1000);
    },

    getStationInfo() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/reading", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationDetails = response.data;
          this.addMapMarker();

          this.items = [];
          let that = this;
          this.stationDetails.forEach(function (res) {
            that.items.push(res.stationid_a);
          });
          this.items.sort();
          this.todo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getParameterInfo() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.parameterDetails = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPOIInfo() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/interest", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.pointOfInterestDetails = response.data;
          this.addPointOfInterestMarker(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initiateMap() {
      var source = new VectorSource();
      var vector = new VectorLayer({
        source: source,
      });

      var baseMap = new GroupLayer({
        layers: [
          this.baseMapLayer.topographicMap,
          this.baseMapLayer.imageryMap,
          this.baseMapLayer.streetMap,
          this.baseMapLayer.googleStreetMap,
          this.baseMapLayer.googleSateliteMap,
        ],
      });

      this.map = new Map({
        controls: defaultControls({
          zoom: false,
        }),
        // .extend([
        //     new ScaleLine({
        //         units: 'metric',
        //     }),
        // ]),
        target: "map",
        layers: [baseMap],
        view: new View({
          projection: "EPSG:4326",
          zoom: 1,
        }),
      });

      this.view = this.map.getView();
      this.view.fit(this.extent);
    },
    addMapMarker() {
      for (let i = 0; i < this.stationDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]),
          description: this.stationDetails[i],
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });
        var markerVectorLayer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            image: new Icon({
              crossOrigin: "anonymous",
              src: this.stationDetails[i].green == 'true' ? this.station_status[0].img : this.stationDetails[i].grey == 'true' ? this.station_status[1].img : this.stationDetails[i].yellow == 'true' ? this.station_status[2].img : '',
              scale: 1.5,
            }),
          }),
        });
        this.map.addLayer(markerVectorLayer);
      }
      this.addMapOverlayReading();
    },
    addMapOverlayReading() {
      // console.log(this.stationDetails);

      for (let i = 0; i < this.stationDetails.length; i++) {
        let elementId = "reading_" + this.stationDetails[i].stationid_a;

        let div = document.createElement("DIV");
        div.setAttribute("id", elementId);

        if (this.stationDetails[i].param_wqi_class == "I") {
          div.setAttribute("class", "reading-div reading-blue");
        } else if (this.stationDetails[i].param_wqi_class == "II") {
          div.setAttribute("class", "reading-div reading-green");
        } else if (this.stationDetails[i].param_wqi_class == "III") {
          div.setAttribute("class", "reading-div reading-yellow");
        } else if (this.stationDetails[i].param_wqi_class == "IV") {
          div.setAttribute("class", "reading-div reading-orange alert-class");
        } else if (this.stationDetails[i].param_wqi_class == "V") {
          div.setAttribute("class", "reading-div reading-red alert-class");
        } else {
          div.setAttribute("class", "reading-div reading-grey");
        }
        console.log(this.stationDetails[i].param_wqi_class);
        div.innerHTML =
          this.stationDetails[i].param_wqi_class == "NA" || this.stationDetails[i].param_wqi_class == null  ? "NA" : this.stationDetails[i].param_wqi;
        document.getElementById("map").appendChild(div);

        var overlay = new Overlay({
          element: document.getElementById(elementId),
          id: this.stationDetails[i].stationid_a,
          offset: [0, -24],
          positioning: "bottom-center",
        });
        overlay.setPosition([
          this.stationDetails[i].LONGITUDE,
          this.stationDetails[i].LATITUDE,
        ]);
        this.map.addOverlay(overlay);

        let labelId = "label_" + this.stationDetails[i].stationid_a;

        let label = document.createElement("div");

        label.setAttribute("id", labelId);
        label.setAttribute("class", "station-label");

        label.innerHTML = this.stationDetails[i].stationid_a;
        document.getElementById("map").appendChild(label);

        var overlayLabel = new Overlay({
          element: document.getElementById(labelId),
          id: "label_" + this.stationDetails[i].stationid_a,
          offset: [0, -75],
          positioning: "top-center",
        });

        overlayLabel.setPosition([
          this.stationDetails[i].LONGITUDE,
          this.stationDetails[i].LATITUDE,
        ]);
        this.map.addOverlay(overlayLabel);
      }
      // this.overlayInsert = true;
      this.getPOIInfo();
    },
    addPointOfInterestMarker() {
      for (let i = 0; i < this.pointOfInterestDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.pointOfInterestDetails[i].longitude,
            this.pointOfInterestDetails[i].latitude,
          ]),
          details: this.pointOfInterestDetails[i],
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });
        // var pointOfInterestMarker = new VectorLayer({
        //     name: 'Point Of Interest',
        //     source: vectorSource,
        //     style: new Style({
        //             image: new Icon({
        //                 color: 'black',
        //                 imgSize: [22, 35],
        //                 crossOrigin: 'anonymous',
        //                 src: require('@/assets/mapviewer/station pin.svg'),
        //                 scale: 1.5
        //             })
        //         }),
        //     visible: false
        // });

        var pointOfInterestMarker = new VectorLayer({
          name: "Point Of Interest",
          source: vectorSource,
          style: this.pointStyle.default,
          visible: false,
        });
        this.map.addLayer(pointOfInterestMarker);
      }
    },
    changeBasemap(layerName) {
      if (layerName == "Imagery Map") {
        this.baseMapLayer.imageryMap.setVisible(true);
        this.baseMapLayer.topographicMap.setVisible(false);
        this.baseMapLayer.streetMap.setVisible(false);
        this.baseMapLayer.googleStreetMap.setVisible(false);
        this.baseMapLayer.googleSateliteMap.setVisible(false);
      } else if (layerName == "Topographic Map") {
        this.baseMapLayer.imageryMap.setVisible(false);
        this.baseMapLayer.topographicMap.setVisible(true);
        this.baseMapLayer.streetMap.setVisible(false);
        this.baseMapLayer.googleStreetMap.setVisible(false);
        this.baseMapLayer.googleSateliteMap.setVisible(false);
      } else if (layerName == "Streets Map") {
        this.baseMapLayer.imageryMap.setVisible(false);
        this.baseMapLayer.topographicMap.setVisible(false);
        this.baseMapLayer.streetMap.setVisible(true);
        this.baseMapLayer.googleStreetMap.setVisible(false);
        this.baseMapLayer.googleSateliteMap.setVisible(false);
      } else if (layerName == "Google Streets Map") {
        this.baseMapLayer.imageryMap.setVisible(false);
        this.baseMapLayer.topographicMap.setVisible(false);
        this.baseMapLayer.streetMap.setVisible(false);
        this.baseMapLayer.googleStreetMap.setVisible(true);
        this.baseMapLayer.googleSateliteMap.setVisible(false);
      } else if (layerName == "Google Satelite Map") {
        this.baseMapLayer.imageryMap.setVisible(false);
        this.baseMapLayer.topographicMap.setVisible(false);
        this.baseMapLayer.streetMap.setVisible(false);
        this.baseMapLayer.googleStreetMap.setVisible(false);
        this.baseMapLayer.googleSateliteMap.setVisible(true);
      }
    },
    zoomStation(stationId) {
      for (let i = 0; i < this.stationDetails.length; i++) {
        if (this.stationDetails[i].stationid_a == stationId) {
          this.map
            .getView()
            .setCenter([
              this.stationDetails[i].LONGITUDE,
              this.stationDetails[i].LATITUDE,
            ]);
          this.view.animate({
            zoom: 15,
            duration: 500,
          });
          // map.getView().setZoom(15);
        }
      }
    },
    home() {
      this.view = this.map.getView();
      this.view.fit(this.extent, {
        duration: 500,
      });
    },
    pinOfInterestSetting() {
      let that = this;
      this.pointOfInterestVisible == true
        ? (this.pointOfInterestVisible = false)
        : (this.pointOfInterestVisible = true);

      this.map.getLayers().forEach(function (el) {
        if (el.get("name") === "Point Of Interest") {
          el.setVisible(that.pointOfInterestVisible);
        }
      });
    },
    removeHighlight() {
      if (this.selectedPOI != "") {
        this.selectedPOI.setStyle(this.pointStyle.default);
      }
    },
  },

  mounted() {
    this.getStationInfo();
    this.getParameterInfo();
    this.initiateMap();
    // console.log(this.$vuetify.breakpoint.xs);
    // this.addMapMarker();
    // this.addMapOverlayReading();

    this.$nextTick(function () {
      // this.initiateMap();
      const that = this;
      this.map.on("click", function (evt) {
        // Use to hide basemap and information card
        that.displayCard = false;
        that.displayCardMap = false;

        const feature = this.forEachFeatureAtPixel(
          evt.pixel,
          (feature) => feature
        );
        if (feature != undefined) {
          if (feature.get("description") != undefined) {
            that.station_id = feature.get("description").stationid_a;
            let data = feature.get("description");

            let date = new Date(data.DATETIME);
            let year = date.getFullYear();
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);
            let hours = date.getHours();
            let minutes = date.getMinutes() == "0" ? "00" : date.getMinutes();
            let seconds = date.getSeconds() == "0" ? "00" : date.getSeconds();
            data["DATETIME"] =
              year +
              "-" +
              month +
              "-" +
              day +
              " " +
              hours +
              ":" +
              minutes +
              ":" +
              seconds;

            that.selectedFeature = data;

            console.log(that.selectedFeature);

            that.wqi_parameter = {
              parameter: [],
              subIndex: [],
            };
            that.testParameterDetails = [];
            console.log(that.testParameterDetails);

            for (let k in data) {
              if (k.includes("param_")) {
                if (k.includes("si")) {
                  let obj = {};
                  obj["name"] =
                    k.replace("param_si_", "") == "ph"
                      ? "SI_pH"
                      : k.replace("param_si_", "SI_").toString().toUpperCase();
                  obj["value"] = that.selectedFeature[k];
                  that.testParameterDetails.push(obj);
                } else if (k.includes("class")) {
                  let obj = {};
                  obj["name"] = k.toString().toUpperCase();
                  obj["value"] = that.selectedFeature[k];
                  that.testParameterDetails.push(obj);
                } else {
                  let str =
                    k.replace("param_", "") === "ph"
                      ? "pH"
                      : k.replace("param_", "") === "do_sat"
                      ? "Dissolve Oxygen"
                      : k.replace("param_", "").toString().toUpperCase();
                  that.wqi_parameter.parameter[str] = that.selectedFeature[k];

                  let obj = {};
                  obj["name"] =
                    k.replace("param_", "") == "ph"
                      ? "pH"
                      : k.replace("param_", "").toString().toUpperCase();
                  obj["value"] = that.selectedFeature[k];
                  that.testParameterDetails.push(obj);
                }
              } else {
                if(k == 'TEMPERATURE'){
                  let obj = {};
                  obj["name"] = k;
                  obj["value"] = that.selectedFeature[k];
                  obj["flag"] = that.selectedFeature['TEMP_FLAG'];
                  that.testParameterDetails.push(obj);
                } else {
                  let obj = {};
                  obj["name"] = k;
                  obj["value"] = that.selectedFeature[k];
                  obj["flag"] = that.selectedFeature[k+'_FLAG'];
                  that.testParameterDetails.push(obj);
                }
              }
            }

            let arrangedData = that.testParameterDetails;

            that.parameter = [];
            let subIndex = [];
            // for(let k in arrangedData){
            //     that.parameterDetails.forEach(function(res){
            //         if(arrangedData[k].name == res.reading){
            //             res['value'] = arrangedData[k].value;
            //             res['sub_index'] = null;
            //             if(!res.reading.includes('SI_')){
            //                 that.parameter.push(res);
            //             } else {
            //                 subIndex.push(res);
            //             }
            //         }
            //     })
            // }

            axios
              .get(
                "https://apis.spatialworks.com.my/sarawak/cms/params?stationid=" +
                  that.station_id,
                {
                  headers: {
                    Authorization: "Bearer " + that.$store.getters.user.token,
                  },
                }
              )
              .then((response) => {
                // console.log(response.data);
                for (let k in arrangedData) {
                  response.data.forEach(function (res) {
                    if (arrangedData[k].name == res.reading) {
                      let value = arrangedData[k].value == null && arrangedData[k].flag == null ? 'NA' : arrangedData[k].value != null ? arrangedData[k].value : arrangedData[k].flag != null ? arrangedData[k].flag : 'NA'
                      let unit = res['unit'] == null ? '' : res['unit']
                      arrangedData[k].flag == 'DOR' || arrangedData[k].flag == 'OUTL' || arrangedData[k].flag == 'C' || arrangedData[k].flag == 'M' ? unit = '' : ''
                      unit == 'Index' ? unit = '' : ''
                      value == 'NA' ? unit = '' : ''
                      res["value"] = value + ' ' + unit;
                      res["sub_index"] = null;
                      if (!res.reading.includes("SI_")) {
                        that.parameter.push(res);
                      } else {
                        subIndex.push(res);
                      }
                    }
                  });

                  for (let j in subIndex) {
                    that.parameter.forEach(function (res) {
                      if (
                        res.reading == subIndex[j].reading.replace("SI_", "")
                      ) {
                        res["sub_index"] = subIndex[j].value;
                      }
                    });
                  }
                  var seenNames = {};
                  that.parameter = that.parameter.filter(function (currentObj) {
                    if (currentObj.reading in seenNames) return false;
                    else {
                      seenNames[currentObj.reading] = true;
                      return true;
                    }
                  });

                  for (let l in arrangedData) {
                    that.parameter.forEach(function (res) {
                      if (
                        "PARAM_" + res.reading.toUpperCase() + "_CLASS" ==
                        arrangedData[l].name
                      ) {
                        res["class"] = arrangedData[l].value;
                      }
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            that.selectedPOI != ""
              ? that.selectedPOI.setStyle(that.pointStyle.default)
              : "";
            that.selectedPOI = feature;
            feature.setStyle(that.pointStyle.selected);
            that.selectedFeature = feature.get("details");
          }
          that.displayInformation = true;

          var point = feature.getGeometry();
          this.getView().centerOn(
            point.getCoordinates(),
            [100, 100],
            [100, 50]
          );
          this.getView().setZoom(15);
          this.getView().centerOn(
            point.getCoordinates(),
            [100, 100],
            [100, 50]
          );
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>

.height-list {
  height: 5px;
}

#map {
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.dashboard-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

#map-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.map-icon-btn {
  margin-block-end: 10px;
}

.info-card {
  position: absolute;
  top: 66px;
  left: 80px;
  z-index: 1;
  overflow: auto;
}

.info-card > div:nth-child(2) {
  padding: 12px 16px;
}

.station-info-card {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  overflow: auto;
}

.poi-description {
  white-space: normal !important;
}

.param-class-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

::v-deep tbody tr:nth-of-type(even) {
   background-color: rgba(0, 0, 0, .05);
}

#map::v-deep .reading-info-card {
  padding: 15px;
  text-align: center;
}

#map::v-deep .reading-div {
  padding-left: 6px;
  padding-right: 6px;
  z-index: 2;
}

#map::v-deep .station-label {
  text-shadow: 2px 2px 8px #fff;
}

#map::v-deep .reading-blue {
  border: 2px solid #fff;
  color: #fff;
  background-color: #3d8af7;
}

#map::v-deep .reading-green {
  border: 2px solid #fff;
  background-color: #7cde6b;
}

#map::v-deep .reading-yellow {
  border: 2px solid #fff;
  background-color: #ffdd03;
}

#map::v-deep .reading-orange {
  border: 2px solid #fff;
  background-color: #ffa834;
}

#map::v-deep .reading-red {
  border: 2px solid #fff;
  color: #fff;
  background-color: #ff3823;
}

#map::v-deep .reading-grey {
  border: 2px solid #fff;
  background-color: #cccccc;
}

#map::v-deep .alert-class {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    transform: scale(1);
    opacity: 0.25;
  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.25;
  }
}
</style>


